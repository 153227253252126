<template>
  <div class="home">
    <slide-section
      :class="{animated: true, slow: true, fadeOut, fadeIn}"
      title="O Andarilho Clemente"
      subtitle="Último Lançamento"
      :options="options"
      :play-default="true"
    >
      <img
        slot="MainImage"
        src="../assets/posts.svg"
        alt="posts"
      >
    </slide-section>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import SlideSection from '@/components/SlideSection.vue';

export default {
    name: 'Home',
    components: { SlideSection },
    data() {
        return {
            fadeIn: false,
            fadeOut: false,
            options: [
                // {
                //     name: 'Prévia',
                //     path: false,
                //     preview: ['o-andarilho-new-cover.png', 'o-andarilho-new-cover-empty'],
                // },
                {
                    name: 'Comprar',
                    path: 'https://www.amazon.com.br/Andarilho-Clemente-CH-BLACKSMITH-ebook/dp/B0977RD3C9',
                    externalLink: true,
                }, {
                    name: 'Conteúdo Extra (em breve)',
                    path: './',
                }],
        };
    },
    computed: {
        ...mapState(['playing', 'firstUser']),
    },
    watch: {
        playing() {
            if (this.playing && this.firstUser) {
                this.fadeOut = true;
                this.setFirstUser(false);
                setTimeout(() => {
                    this.fadeIn = true;
                    this.fadeOut = false;
                }, 4000);
            }
        },
    },
    methods: {
        ...mapMutations(['setPlaying', 'setFirstUser']),
    },
};
</script>
