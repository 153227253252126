<template>
  <section class="SlideSection">
    <figure>
      <figcaption>
        <h3 class="subtitle">
          {{ subtitle }}
        </h3>
        <h2 class="title">
          {{ title }}
        </h2>
        <ul class="options">
          <div
            v-for="(option, index) in options"
            :key="index"
          >
            <router-link
              v-if="!option.externalLink"
              :to="option.path"
              tag="li"
            >
              {{ option.name }}
            </router-link>
            <a
              v-else
              :href="option.path"
              target="_blank"
            >
              {{ option.name }}
            </a>
          </div>
        </ul>
      </figcaption>
    </figure>
    <slot
      name="MainImage"
      class="mainImage"
    />
  </section>
</template>
<script>
export default {
    name: 'SlideSection',
    props: {
        title: {
            type: String,
            default: 'Default Title',
        },
        subtitle: {
            type: String,
            default: 'Default Subtitle',
        },
        background: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default() {
                return [{
                    name: 'Prévia',
                    path: './',
                }, {
                    name: 'Comprar',
                    path: './',
                }, {
                    name: 'Conteúdo Extra',
                    path: './',
                }];
            },
        },
    },
};
</script>
<style scoped>
.SlideSection {
  width: 80vw;
  height: calc(100vh - 180px);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
}

.SlideSection figure {
  align-self: center;
}

.SlideSection .title{
  font-size: 108px;
  font-family: canter-bold, sans-serif;
  font-weight: lighter;
}

.SlideSection .subtitle {
  font-size: 60px;
  font-family: yanone-light, sans-serif;
  font-weight: 100;
}

.SlideSection ul {
  list-style: none;
  border-left: 10px solid var(--red);
  padding: 0 20px;
}
.SlideSection ul li, .SlideSection ul a {
  padding: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  display: block;
  position: relative;
}

.SlideSection ul li:hover::before, .SlideSection ul a:hover::before {
  content: "";
  position: absolute;
  top: 10px; /* At the bottom of the tooltip */
  left: -20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent var(--red);
}

.SlideSection ul li:hover, .SlideSection ul a:hover {
  color: var(--red);
}

.SlideSection .MainImage {
  width: 200px;
  height: auto;
  float: right;
}

@media (max-height: 450px) {
  .SlideSection {
    align-items: flex-start;
    padding: 0;
    overflow: auto;
    height: auto;
  }
  .SlideSection img {
    width: 250px; /* Reduz tamanho da imagem */
    position: fixed;
    right: 20px;
    bottom: -100px;
    z-index: -1;

    /*margin: 20px 0; !* Adiciona margem para espaçamento *!*/
  }
}

@media (max-width: 768px) {
  .SlideSection {
    width: 100vw;
    margin-top: 10vh;
    height: auto;
    padding: 30px; /* Adiciona padding para espaçamento */
    overflow: auto;
  }

  .SlideSection .title {
    font-size: 80px; /* Diminui tamanho da fonte do título */
    padding: 30px 0;
  }

  .SlideSection .subtitle {
    font-size: 42px; /* Diminui tamanho da fonte do subtítulo */
  }

  .SlideSection img {
    width: 250px; /* Reduz tamanho da imagem */
    position: fixed !important;
    right: 20px !important;
    bottom: 0 !important;
    z-index: -1 !important;

    /*margin: 20px 0; !* Adiciona margem para espaçamento *!*/
  }
}
</style>
